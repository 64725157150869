@include breakpoint($mq-topnav-visible) {
  .hbg,
  .site-title-nav .fullscreen-mask,
  .topnav-toggle {
    display: none !important;
  }

  #topnav {
    --nav-section: 26px;
    --nav-section-hover: 35px;

    @include breakpoint($mq-site-header-tall) {
      --nav-section: 48px;
      --nav-section-hover: 53px;
    }

    display: flex;

    section {
      position: relative;
      height: var(--nav-section);

      > a {
        color: $c-font-page;
        text-shadow: $text-shadow;
        display: block;
        height: var(--nav-section);
        line-height: $site-header-height;
        padding: 0 0.7rem;
        text-transform: uppercase;
        border-#{$start-direction}: 2px solid transparent;
      }

      .home {
        display: none;
      }
    }

    div {
      visibility: hidden;
      max-height: inherit;
      position: absolute;
      #{$start-direction}: 0;
      background: $c-bg-header-dropdown;
      min-width: 10rem;
      box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.3);
      border-radius: 0 3px 3px 3px;
      border-#{$start-direction}: 2px solid $c-primary;
      @if $theme == 'transp' {
        @include back-blur();
      }

      a {
        display: block;
        padding: 0.6rem 0.7rem;
        color: $c-header-dropdown;

        &:hover {
          background: $c-primary;
          &,
          &::after {
            color: $c-primary-over;
          }
        }

        &:first-child {
          border-radius: 0 3px 0 0;
        }

        &:last-child {
          border-radius: 0 0 3px 1px;
        }
      }
    }

    &.blind div {
      display: block;
      margin-#{$start-direction}: -9000px;
    }

    &.hover section:hover,
    section:active {
      > a {
        height: var(--nav-section-hover);
        background: $c-bg-header-dropdown;
        color: $c-header-dropdown;
        border-color: $c-primary;
      }

      div {
        visibility: visible;
        max-height: none;
        padding-bottom: 8px;
      }
    }
  }
}
