.is::before,
[data-icon]::before,
.is-after::after {
  @extend %data-icon;
}

.is.text::before,
.text[data-icon]::before {
  @include margin-inline-end(0.4em);
}

.is-green::before {
  color: $c-good;
}

.is-red::before {
  color: $c-bad;
}

.is-gold::before {
  color: $c-brag;
}

// mirror arrow icons in RTL

@if $direction == 'rtl' {
  [data-icon='#{$licon-Wings}'],
  [data-icon='#{$licon-PlayTriangle}'],
  [data-icon='#{$licon-GreaterThan}'],
  [data-icon='#{$licon-LessThan}'],
  [data-icon='#{$licon-JumpFirst}'],
  [data-icon='#{$licon-JumpPrev}'],
  [data-icon='#{$licon-JumpNext}'],
  [data-icon='#{$licon-JumpLast}'] {
    &:not(.no-mirror)::before {
      display: inline-block;
      transform: scaleX(-1);
    }
  }
}
