dialog .help {
  align-items: center;
  text-align: center;
  padding: 0.8em 0;

  h2 {
    margin: 0.5em 0 0 0;
  }

  table {
    width: 100%;
  }

  th p {
    margin: 1.2em 0 0.6em 0;
    background: $c-brag;
    color: $c-brag-over;
    font-weight: bold;
    padding: 0.3em 0;
  }

  td {
    padding: 0.2em 0.5em;
    text-align: left;
  }

  or {
    margin-#{$start-direction}: 0.2em;
    opacity: 0.5;
  }

  kbd {
    display: inline-block;
    padding: 4px 5px;
    margin-#{$start-direction}: 3px;
    font-family: monospace;
    line-height: 10px;
    color: #444;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px #ccc;
    border-bottom-color: #bbb;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #bbb;
  }

  .keys {
    padding-#{$end-direction}: 1em;
    text-align: right;
    white-space: nowrap;
  }

  .desc:first-letter {
    text-transform: uppercase;
  }
}
