@if $theme-dark {
  body.dark-board {
    --dark-factor: 0.4;

    cg-board,
    .is3d cg-board::before {
      background-color: rgba(0, 0, 0, var(--dark-factor));
      background-blend-mode: darken;
    }

    &.coords-in coord {
      color: #fff !important;
      opacity: calc(1 - var(--dark-factor));
    }

    cg-board square {
      opacity: calc(1 - var(--dark-factor));
    }

    cg-board piece {
      filter: brightness(calc(1 - 0.6 * var(--dark-factor)));
    }

    cg-board square.move-dest {
      background: radial-gradient(rgba(8, 46, 8, 0.8) 19%, rgba(0, 0, 0, 0) 20%);
    }

    cg-board square.oc.move-dest {
      background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 85, 0, 0.3) 80%);
    }
  }
}
