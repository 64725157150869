html {
  @extend %base-font-fallback;

  @include fluid-size('font-size', 12px, 14px);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  @extend %roboto;

  font-weight: normal;
  font-size: 1em;
}

h1 {
  @include fluid-size('font-size', 20px, 40px);

  a {
    color: $c-link-dim;

    &:hover {
      color: $c-link;
    }
  }
}

h2 {
  @include fluid-size('font-size', 16px, 30px);
}

.ninja-title {
  @extend %base-font;

  font-size: 1em;
}
