$vp-min-width: 320px !default;
$vp-max-width: 1200px !default;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-size($prop, $min-size, $max-size, $min-vw: $vp-min-width, $max-vw: $vp-max-width) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;

      @media (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  } @else {
    @error "fluid-size requires that all values have the same unit";
  }
}
