$vp-min-width: 320px;
$vp-max-width: 1200px;

body {
  --site-header-height: #{$site-header-short-height};

  @include breakpoint($mq-site-header-tall) {
    --site-header-height: #{$site-header-tall-height};
  }

  --site-header-margin: 0px;

  &.header-margin {
    --site-header-margin: 1em;
  }

  --main-margin: 0;

  @include breakpoint($mq-main-margin) {
    --main-margin: 1vw;
    margin-bottom: $block-gap;
  }
}

#main-wrap {
  display: grid;
  grid-template-areas: '. . main . .';
  --main-max-width: #{$main-max-width};
  grid-template-columns: $main-margin 1fr minmax(auto, var(--main-max-width)) 1fr $main-margin;

  &.full-screen {
    --main-max-width: auto;
  }

  &.full-screen-force {
    --main-max-width: 100%;
  }

  margin-top: $site-header-margin;

  @media (hover: none) {
    body.clinput & {
      display: none;
    }
  }
}

main {
  grid-area: main;

  &.page-small {
    max-width: 1000px;
    margin: auto;
    width: 100%;
  }
}
