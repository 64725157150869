@import 'board-2d';
@import 'dark-board';

cg-board {
  @extend %box-shadow, %abs-100;

  top: 0;
  left: 0;
  user-select: none;
  line-height: 0;
  background-size: cover;

  .manipulable & {
    cursor: pointer;
  }
}

square {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5%;
  height: 12.5%;
  pointer-events: none;

  &.move-dest {
    background: radial-gradient(rgba(20, 85, 30, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }

  &.premove-dest {
    background: radial-gradient(rgba(20, 30, 85, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }

  &.oc.move-dest {
    background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 85, 0, 0.3) 80%);
  }

  &.oc.premove-dest {
    background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 30, 85, 0.2) 80%);
  }

  body.green &.last-move,
  body.green-plastic &.last-move,
  body.marble &.last-move {
    background-color: rgba(0, 155, 199, 0.41);
  }

  &.last-move {
    will-change: transform;
    background-color: rgba(155, 199, 0, 0.41);

    body.horsey &:not(.move-dest) {
      background: url(../images/board/horsey.last-move.png);
      background-size: cover;
    }
  }

  &.check {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 0, 0, 1) 0%,
      rgba(231, 0, 0, 1) 25%,
      rgba(169, 0, 0, 0) 89%,
      rgba(158, 0, 0, 0) 100%
    );
  }

  &.selected {
    background-color: rgba(20, 85, 30, 0.5);

    body.horsey & {
      background: url(../images/board/horsey.selected.png);
      background-size: cover;
    }
  }

  &.current-premove {
    background-color: rgba(20, 30, 85, 0.5) !important;

    body.horsey & {
      background: url(../images/board/horsey.current-premove.png);
      background-size: cover;
    }
  }

  &.move-dest:hover {
    background: rgba(20, 85, 30, 0.3);

    body.horsey & {
      background: url(../images/board/horsey.move-dest.png);
      background-size: cover;
    }
  }

  &.premove-dest:hover {
    background: rgba(20, 30, 85, 0.2);
  }

  &.bh1 piece {
    opacity: 0.98;
  }
}

piece {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5%;
  height: 12.5%;
  background-size: cover;
  z-index: z('cg__piece');
  will-change: transform;
  pointer-events: none;

  &.dragging {
    cursor: move;
    z-index: z('cg__piece.dragging') !important;
  }

  &.anim {
    z-index: z('cg__piece.anim');
  }

  &.fading {
    z-index: z('cg__piece.fading');
    opacity: 0.5;
  }

  &.ghost {
    opacity: 0.3;
  }
}

cg-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  right: 0;
}

cg-container .cg-shapes,
cg-container .cg-custom-svgs,
cg-auto-pieces {
  overflow: visible;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

cg-container .cg-shapes {
  opacity: 0.6;
  z-index: z('cg__svg.cg-shapes');
}

cg-container .cg-custom-svgs {
  z-index: z('cg__svg.cg-custom-svgs');
}

cg-container .cg-shapes {
  overflow: hidden;
}

cg-container .cg-custom-svgs svg {
  overflow: visible;
}

cg-auto-pieces {
  z-index: z('cg__cg-auto-pieces');

  piece {
    opacity: 0.3;
  }
}
