@import '../component/subnav';

.page-menu {
  display: grid;
  grid-template-areas: 'menu' 'content';

  @include breakpoint($mq-subnav-side) {
    grid-template-columns: max-content auto;
    grid-template-rows: min-content;
    grid-template-areas: 'menu content';
  }

  &.page-small {
    max-width: 1000px;
    margin: inherit;
  }

  &__menu {
    grid-area: menu;
  }

  &__content {
    grid-area: content;
    height: 100%;

    // overflow: hidden; /* fixes crazy text overflow on Fx */
  }

  &__content.box {
    /* ensure the content is as high as the menu */
    min-height: 100%;
  }
}
